/**
 * 项目详情-获取项目任务列表
 * @param {*} param
 * @returns
 */
import {
  postRequest,
  updateRequest,
  deleteRequest,
  getRequest,
} from "@/utils/http";

export const getTaskBoard = (param) =>
  getRequest("/project-manage/task/getTaskBoard", param);
/**
 * 创建任务
 * @param {*} param
 * @returns
 */
export const createTask = (param) =>
  postRequest("/project-manage/task/create", param);

/**
 * 获取任务详情
 * @param {*} param
 * @returns
 */
export const getTask = (id) => getRequest(`/project-manage/task/${id}`);

/**
 * 删除任务
 * @param {*} param
 * @returns
 */
export const deleteTask = (id) => deleteRequest(`/project-manage/task/${id}`);

/**
 * 更新任务
 * @param {*} param
 * @returns
 */
export const updateTask = (param) =>
  updateRequest(`/project-manage/task/update`, param);

/**
 * 批量更新任务
 * @param {*} param
 * @returns
 */
export const bulkupdateTask = (param) =>
  postRequest(`/project-manage/task/bulkupdate`, param);

/**
 * 认领/转移任务
 * @param {*} param
 * @returns
 */
export const claimTransfer = (param) =>
  getRequest(`/project-manage/task/claimOrTransfer`, param);

/**
 * 获取任务动态
 * @param {*} param
 * @returns
 */
export const getTaskLog = (param) =>
  getRequest(`/project-manage/task/getLogList`, param);

/**
 * 发表任务动态
 * @param {*} param
 * @returns
 */
export const createTaskLog = (param) =>
  postRequest(`/project-manage/task/createLog`, param);

/**
 * 获取关联项
 * @param {*} param taskId filter
 * @returns
 */
export const getRefrenceList = (
  param = {
    taskId: "",
    MaxResultCount: 10,
    SkipCount: 0,
    Filter: null,
  }
) => getRequest(`/project-manage/projectTaskAttach/getlist`, param);

/**
 * 添加关联
 */
export const createRefrence = (
  param = {
    taskId: "",
    sources: [
      {
        sourceId: "",
        attachType: 0,
      },
    ],
  }
) => postRequest(`project-manage/projectTaskAttach/create`, param);

/** 添加关联 */
export const saveRefrence = (
  param = {
    taskId: "",
    sources: [
      {
        sourceId: "",
        attachType: 0,
      },
    ],
  }
) => postRequest(`project-manage/projectTaskAttach/save`, param);

/** 删除关联 */
export const deleteRefrence = (id) =>
  deleteRequest(`/project-manage/projectTaskAttach/${id}`);

/** 批量更新 */
export const updateManyRefrence = (param = []) =>
  postRequest(`project-manage/projectTaskAttach/updateMany`, param);

/**获取已绑定数据 */
export const getBindedDataAsync = (param) =>
  getRequest(`project-manage/projectTaskAttach/binded`, param);

/**项目任务模板上传解析 */
export const getProjectTaskTemplateListAsync = (params) =>
  postRequest(`/project-manage/task/getProjectTaskTemplateListAsync`, params);

/**项目任务保存 */
export const addProjectTask = (params) =>
  postRequest(`/project-manage/task/addProjectTask`, params);
